import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  InputBase
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Header from "../../../blocks/landingpage/src/Header.web";
import customTheme from '../../../components/src/CustomTheme.web'; 
import ViewChat from "./ViewChat.web";
import CustomModal from "../../../components/src/CustomModal.web";
import { styled } from "@material-ui/core";
import { createNewGroupIcon, searchIcon, profileImg } from './assets';
import CustomFontVariant from "../../../../packages/components/src/CustomFontVariant.web";

const PlusIcon = require("../assets/plus_icon.png")

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddRoomModal = () => (
    <CustomModal
      open={this.state.createChatModalOpen}
      data-test-id="handleCreateChatModal"
      cancelButtonText="Cancel"
      doneButtonText="Send"
      onCancel={this.handleCloseCreateChatModal}
      onDone={this.handleSendCreateChatModal}
      title="Create chat"
    >
      <CreateChatButtonBox>
        <CreateGroupButton>
          <CreateGroupIconBox>
            <CreateGroupIcon src={createNewGroupIcon}></CreateGroupIcon>
          </CreateGroupIconBox>
          <CreateGroupTitle>New Group</CreateGroupTitle>
        </CreateGroupButton>
      </CreateChatButtonBox>
     
      <SearchFriendBox>
        <SearchIcon src={searchIcon} alt="" />
        <SearchInput placeholder="search friends and groups" />
      </SearchFriendBox>
      
      <SearchList>
        <SearchItemBox>
          {profileImg ? (
            <FriendProfileImg src={profileImg} alt="" />
          ) : (
            <Box sx={webStyles.avatarCircle}>
                N
            </Box>
              )}
          <FriendProfileName>Name</FriendProfileName>
        </SearchItemBox>
        <ButtonContained>
          Message
        </ButtonContained>
      </SearchList>
      
    </CustomModal>
  );

  renderRoomList = () => {
    const { chatList, selectedChatId } = this.state;

    return (
      <>
        {chatList.map((item: IChat, index: number) => (
          <Box
            data-test-id={`btnShowChat${index}`}
            key={`room-${index}`}
            sx={{
              ...webStyles.listItemContainer,
              backgroundColor: selectedChatId === item.id ? '#EAE7DC' : 'transparent',
            }}
            onClick={() => this.handleChatSelection(item)}
          >
            <Box sx={{ marginRight: '12px'}}>
              {item.attributes.photo ? (
                <img src={item.attributes.photo} alt={item.attributes.receiver_name} style={{ width: '44px', height: '44px', borderRadius: '50%' }} />
              ) : (
                <Box sx={webStyles.avatarCircle}>
                  {item.attributes.receiver_name.charAt(0).toUpperCase()}
                </Box>
              )}
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700', fontSize: '14px', lineHeight: '22px' }}>{item.attributes.receiver_name}</Typography>
              </Box>

              <Typography style={{ color: '#0F172A', fontSize: '14px', lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '218px' }}>
                {item.attributes.last_message}
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: '#64748B', fontSize: '14px', lineHeight: '22px', marginBottom: '4px' }}>
                {this.getLastTimeMessage(item.attributes.created_at)}
              </Typography>
              {item.attributes.unread_count > 0 && (
                <Box sx={webStyles.unreadCount}>
                  {item.attributes.unread_count}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Box sx={{minHeight: '78px'}}>
              <Header activeTab="4" navigation={this.props.navigation} id={""} />
            </Box>

            <Box sx={webStyles.pageContainer}>
              <Box sx={webStyles.leftSideLayout}>
                  <Box sx={{display:"flex", justifyContent: "space-between", alignItems: "center", padding: "14px 24px", borderBottom:"1px solid #E2E8F0", marginBottom:"16px"}}>
                    <Typography style={{
                        fontWeight: 700,
                        fontSize: "30px",
                        lineHeight: "40px",
                        color: "#1E293B",
                        fontFamily: "Open Sans",
                      }}>Chat</Typography>
                    <Button
                      data-test-id={"createChatRoomBtn"}
                      variant="contained"
                      color="primary"
                      onClick={this.showCreateChatModal} 
                      style={{padding: "10px", backgroundColor: '#EAE7DC', borderRadius: '8px', minWidth: 'auto', textDecoration: 'none', boxShadow: 'none'}}>
                      <img src={PlusIcon} alt="Plus Icon" style={webStyles.image} />
                    </Button>
                  </Box>

                  <Box sx={{borderBottom:"1px solid #E2E8F0"}}>
                    <ToggleButtonGroup
                      data-test-id="handleOptionChangeButton"
                      value={this.state.selectedOption}
                      exclusive
                      onChange={this.handleOptionChange}
                      aria-label="chat options"
                      sx={webStyles.toggleButtonGroup}
                    >
                      <ToggleButton sx={webStyles.toggleButton} value="all" aria-label="all">
                        All
                      </ToggleButton>
                      <ToggleButton sx={webStyles.toggleButton} value="private" aria-label="private">
                        Private
                      </ToggleButton>
                      <ToggleButton sx={webStyles.toggleButton} value="group" aria-label="group">
                        Group
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                  <Box sx={webStyles.roomListContainer}>{this.renderRoomList()}</Box>
                  
              </Box>
              {this.renderAddRoomModal()}

              <Box sx={{width:"100%", overflow: 'auto'}}>
                <ViewChat
                  senderChatUrl={this.state.selectedChatUrls?.sender_chat_url}
                  receiverId={this.state.receiverId}
                  accountId={this.state.accountId}
                  navigation={this.props.navigation} 
                  senderName={this.state.senderName}
                  receiverName={this.state.receiverName}
                  receiverPhoto={this.state.receiverPhoto}
                  lastMessage={this.state.lastMessage}
                  updateChatList={this.updateChatList}
                  id={""}
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  pageContainer: {
    display: "flex",
    flexGrow: '1',
    maxWidth: "100%",
    padding: "0 40px",
    boxSizing: "border-box",
  },
  leftSideLayout: {
    maxWidth: "360px",
    width: "100%",
    height: "100%", 
    borderLeft: "1px solid #E2E8F0",
    borderRight: "1px solid #E2E8F0",
    padding: "6px 0px",
    backgroundColor: customTheme.palette.white.main,
    boxSizing: "border-box",
  },
  image: {
    width: "24px",
    height: "24px",
  },
  toggleButtonGroup: {
    borderRadius: '50px',
    backgroundColor: '#F8FAFC',
    padding: '4px',
    width: "fit-content",
    marginBottom: "16px",
  },
  toggleButton: {
    '&.MuiButtonBase-root': {
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#64748B',
      padding: '8px 12px',
      borderRadius: '50px',
      textTransform: 'none',
      '&.Mui-selected': {
        backgroundColor: '#EAE7DC',
        color: '#000',
      },
    },
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #E2E8F0",
    padding: "16px 24px",
    boxSizing: "border-box",
  },
  avatarCircle: {
    fontFamily: 'Open Sans',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    backgroundColor: '#EAE7DC99',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    border: '2px solid #CBD5E1',
  },
  unreadCount: {
    fontFamily: 'Open Sans',
    backgroundColor: '#EAE7DC',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
  },
};

const CreateChatButtonBox = styled("div")({
  padding: '24px 0',
  marginBottom: '30px',
  borderBottom: '1px solid #CBD5E1',
});

const CreateGroupButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const CreateGroupIconBox = styled("div")({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#EAE7DC',
  borderRadius: '50%',
  marginRight: '24px',
  padding: '10px',
});

const CreateGroupIcon = styled("img")({
  width: '24px',
  height: '24px'
});

const CreateGroupTitle = styled("h5")({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  textTransform: 'initial',
  color: 'black',
  margin: 0,
});

const SearchFriendBox = styled("div")({
  display: 'flex',
  alignItems: 'center',
  border: '2px solid #EAE7DC',
  borderRadius: '30px',
  padding: '10px 8px',
  marginBottom: '30px',
});

const SearchIcon = styled("img")({
  width: '24px',
  height: '24px',
  marginRight: '10px',
});

const SearchInput = styled(InputBase)({
  fontFamily: 'Open Sans',
    padding: 0,
    width: '100%',
    '&::placeholder': {
      fontSize: '16px',
      color: '#94A3B8',
    },
});

const SearchList = styled("div")({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SearchItemBox = styled("div")({
  display: 'flex',
  alignItems: 'center',
});

const FriendProfileImg = styled("img")({
  width: '56px',
  height: '56px',
  borderRadius: '50%',
});

const FriendProfileName = styled("div")({
  ...CustomFontVariant.palette.font20400,
  paddingLeft: '16px',
});

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
});
// Customizable Area End
