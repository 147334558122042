import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@mui/material";
import { getStorageData } from "../../../framework/src/Utilities";
import { EmojiClickData } from 'emoji-picker-react';
import { group, media } from "./assets";
import firebase from '../../../web/firebase'

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IMessage {
  id: string;
  text: string;
  sender: {
    id: number;
    name: string;
  };
  receiver: {
    id: number;
    name: string;
  };
  isRead: boolean;
  createdAt: number;
  attachmentUrls?: string[];
}

export interface IChatSendMessage {
  receiver_id: number; 
  message: string;
  attachments?: { filename: string; attachment: string; }[];
};

export interface IAttachments {
  imageUrl: string,
  fileName: string,
  fileSize: number,
  docRes: Blob
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  senderChatUrl: string | undefined;
  receiverId: number | null;
  accountId: number | null;
  senderName: string | null;
  receiverName: string | null;
  receiverPhoto: string | null;
  lastMessage: string | null;
  updateChatList: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IMessage[] | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  fileName: string;
  docRes: unknown;
  isEmojiPickerOpen: boolean;
  keyboardHeight: number;
  muted: boolean | null;
  anchorElCoverOptions: any;
  openChatOptions: boolean;
  viewContact: boolean;
  mediaData: any;
  showDataCount: number;
  unfriend: boolean;
  blockAccount: boolean;
  reportModalOpen: boolean;
  openReportMenu: boolean;
  reportReason: string;
  blockToastOpen: boolean;
  blockToastMsg: string;
  reasonError: string;
  profileImage: string;
  attachments: IAttachments[];
  emptyMessageError: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 137,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      fileName: "",
      docRes: null,
      isEmojiPickerOpen: false,
      keyboardHeight: 0,
      muted: null,
      anchorElCoverOptions: null,
      openChatOptions: false,
      viewContact: false,
      mediaData: this.filesData,
      showDataCount: 6,
      unfriend: false,
      blockAccount: false,
      reportModalOpen: false,
      openReportMenu: false,
      reportReason: "",
      blockToastOpen: false,
      blockToastMsg: " Thank you. We have received your report.",
      reasonError: "",
      profileImage: "",
      attachments: [],
      emptyMessageError: ""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    Keyboard.addListener("keyboardDidShow", this._keyboardDidShow.bind(this));
    Keyboard.addListener("keyboardDidHide", this._keyboardDidHide.bind(this));

    const profileImage = await getStorageData("profile");
    this.setState({ profileImage });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.senderChatUrl !== prevProps.senderChatUrl) {
      this.listenForMessages();
      if (this.props.lastMessage) {
        this.updateReadMessages();
      }
    }
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
    const { senderChatUrl } = this.props;
    if (senderChatUrl) {
      const chatRef = firebase.database().ref(senderChatUrl);
      chatRef.off();
    }
  }

  listenForMessages = () => {
    const { senderChatUrl } = this.props;
  
    let starCountRef = firebase.database().ref(senderChatUrl);
    starCountRef.on('value', (snapshot: any) => {
      const messages: IMessage[] = [];
      for (const messageId in snapshot.val()) {
        const messageData = snapshot.val()[messageId];
        
        const id = messageId;
        const text = messageData.text;
        const sender = {
          id: messageData.sender_details.account_id,
          name: messageData.sender_details.name,
        };
        const receiver = {
          id: messageData.receiver_details.account_id,
          name: messageData.receiver_details.name,
        };
        const isRead = messageData.is_read;
        const createdAt = messageData.createdAt;
        const attachmentUrls = messageData.attachment_urls || [];

        const message: IMessage = {
          id,
          text,
          sender,
          receiver,
          isRead,
          createdAt,
          attachmentUrls,
        };
        
        messages.push(message);
      }

      this.setState({ chatData: messages });
    });
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (accountIdInput: string) => {
    this.setState({ accountIdInput });
  };

  handleMessageChange = (message: string) => {
    this.setState({ message, emptyMessageError: "" });
  };

  sendMessageOnKeyEnter = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      this.handleSendMessage();
    }
  }

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0] as Blob;
      const fileName = files[0].name;
      
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const imageUrl = readerEvent.target?.result as string;
  
        const newAttachment = {
          imageUrl,
          fileName,
          fileSize: file.size,
          docRes: file
        };
  
        this.setState((prevState) => ({
          imageUrl,
          docRes: file,
          fileName,
          isVisiblePreviewModal: true,
          attachments: [...prevState.attachments, newAttachment],
          emptyMessageError: ""
        }));
      };
  
      reader.readAsDataURL(file);
    } else {
      this.setState({
        imageUrl: "",
        fileName: "",
        docRes: null,
        attachments: [],
        isVisiblePreviewModal: false
      });
    }
  };
  

  removeAttachment = (index: number) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter((_, i) => i !== index)
    }));
  }

  handleSendMessage = () => {
    const { message, attachments } = this.state;

    const trimmedMessage = message.trim();

    if (!trimmedMessage && attachments.length === 0) {
      this.setState({ emptyMessageError: "Enter a message to send" });
    } else {
      this.sendChatMessage(this.props.receiverId || 0, message, attachments);
  
      this.setState({
        message: "",
        isVisiblePreviewModal: false,
        attachments: [],
        isEmojiPickerOpen: false,
        emptyMessageError: ""
      });
  
      this.props.updateChatList();
    }
  };

  toggleEmojiPicker = () => {
    this.setState({ isEmojiPickerOpen: !this.state.isEmojiPickerOpen, emptyMessageError: "" });
  };

  handleEmojiSelect = (emojiObject: EmojiClickData) => {
    this.setState({
      message: this.state.message + emojiObject.emoji
    })
  };

  handleInsertImage = () => {
    const refrence = this.fileInputRef.current;
    if (refrence) {
      refrence.click();
    }
  };

  getChatDetails = (token: string, chatId: number) => {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showChatApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = (accountIdInput: string, chatId: number) => {
    if (!accountIdInput || this.isStringNullOrBlank(accountIdInput)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        accounts_id: [Number(accountIdInput)],
        chat_id: Number(chatId),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addUserToChatApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  leaveChatRoom = (chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: parseInt(chatId + "", 10),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (chatId: number, message: string, attachments?: IAttachments[]) => {
    const token = this.state.token;
    const header = {
      "Content-Type": "application/json",
      token,
    };

    const messageData: IChatSendMessage = {
      receiver_id: chatId,
      message,
    };

    if (attachments && attachments.length > 0) {
      messageData.attachments = attachments.map(attachment => ({
        filename: attachment.fileName,
        attachment: attachment.imageUrl,
      }));
    this.setState({ docRes: null });
  }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}/send_message`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(messageData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_location: this.props.senderChatUrl
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat(this.state.accountIdInput, this.state.chatId),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(this.state.chatId),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage(this.state.chatId, this.state.message, this.state.attachments);
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    await this.handleSessionAndToken(message);

    this.handleNavigationMessages(message);

    this.handleApiResponses(apiRequestCallId, message, responseJson);
  }

  async handleSessionAndToken(message: Message) {
    const token = await getStorageData("token");
    if (token) this.setState({ token });
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      
      if (messageData && messageData.meta) {
        const accountId: number = messageData.meta.id;
        this.setState({ accountId });
      }
  
      this.refreshChatInterval = setInterval(
        () => this.getChatDetails(this.state.token, this.state.chatId),
        30000
      );
    }
  }

  handleNavigationMessages(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const { chatId } = chatData;
      this.setState({ chatId }, () =>
        this.getChatDetails(this.state.token, chatId)
      );
    }
  }

  handleApiResponses(apiRequestCallId: string, message: Message, responseJson: any) {
    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
  
    if (
      restApiDataCondition &&
      apiRequestCallId === this.getChatApiCallId &&
      responseJson && responseJson.data
    ) {
      const chatData = responseJson.data;
      const { muted } = chatData.attributes.accounts_chats.find(
        (item: { attributes: { account_id: number } }) =>
          item.attributes.account_id === this.state.accountId
      ).attributes;
      this.setState({ chatData, muted });
    }
  
    if (restApiDataCondition && apiRequestCallId === this.sendMessageApiCallId) {
      this.getChatDetails(this.state.token, this.state.chatId);
    }
  
    if (restApiDataCondition && apiRequestCallId === this.addUserToChatApiCallId) {
      this.hideModal();
      this.getChatDetails(this.state.token, this.state.chatId);
    }
  
    if (restApiDataCondition && apiRequestCallId === this.leaveChatApiCallId) {
      this.props.navigation.goBack();
    }
  }


handleBackArrow = () =>
  this.setState({
    viewContact: false,
    openChatOptions: false,
    anchorElCoverOptions: null,
});

handleClickOptions = (event: any) => {
  this.setState({
    anchorElCoverOptions: event.currentTarget,
    openChatOptions: true,
  });
};

handleClickOptionsClose = () => {
  this.setState({
    anchorElCoverOptions: null,
    openChatOptions: false,
  });
};

handleCloseOptions = () => {
  this.setState({ anchorElCoverOptions: null, openChatOptions: false });
};

handleViewContact = () => {
  this.setState({ viewContact: !this.state.viewContact });
};

filesData = [
  { src: media },
  { src: media },
  { src: media },
  { src: media },
  { src: media },
  { src: media },
  { src: media },
  { src: media },
  { src: media },
  { src: media },
];

chatMessages = [
  {
    id: 1,
    imgSrc: group,
    designMessage: "Designers chat",
    userMessage: "You: Do you know where down",
  },
  {
    id: 2,
    imgSrc: media,
    designMessage: "Designers chat",
    userMessage: "See you tomorrow!",
  },
  {
    id: 2,
    imgSrc: group,
    designMessage: "Designers chat",
    userMessage: "You: I found it in the files.",
  },
];

handleShowMore = () => {
  const { showDataCount, mediaData } = this.state;
  if (showDataCount < mediaData.length) {
    this.setState({ showDataCount: showDataCount + 3 });
  }
};

handleUnfriendModal = () => {
  this.setState({ unfriend: !this.state.unfriend });
  this.handleClickOptionsClose();
};

handleBlockAccountModal = () => {
  this.setState({ blockAccount: !this.state.blockAccount });
  this.handleClickOptionsClose();
};

handleReportModal = () => {
  this.setState({
    reportModalOpen: !this.state.reportModalOpen,
    reportReason: "",
    reasonError: "",
  });
  this.handleClickOptionsClose();
};

handleReportModalDone = () => {
  if (!this.state.reportReason) {
    this.setState({
      reasonError: "Reason is required",
      openReportMenu: false,
    });
    return;
  }
  this.handleReportModal();
  this.setState({ blockToastOpen: true });
};

handleSelectReason = (reportReason: string) => {
  this.setState({ reportReason, openReportMenu: false });
};

handleMenuItem = () => {
  this.setState({ openReportMenu: !this.state.openReportMenu, reasonError: ""});
};

menuOptionsOptions = [
  { label: "View Contact", action: this.handleViewContact },
  { label: "Unfriend", action: this.handleUnfriendModal },
  { label: "Block", action: this.handleBlockAccountModal },
  { label: "Report", action: this.handleReportModal },
];

reportData = [
  { id: 1, report: "Bullying or Harassing" },
  { id: 2, report: "It's Spam" },
  { id: 3, report: "Hate Speech" },
  { id: 4, report: "Promoting Violence" },
  { id: 5, report: "False Information" },
  { id: 6, report: "Others" },
];

handleCloseBlockToast = () => {
  this.setState({ blockToastOpen: false });
};
  // Customizable Area End
}
