import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  NewsFeedData: {
    attributes: {
      account_id: number
      already_disliked: boolean
      already_liked: boolean
      article: {
        article_type: "Currently Featured" | "Likely to be featured" | "No label" | string
        created_at: string
        cumulative_score: number
        description: string
        id: number
        importance_score: number
        points: string
        recency_score: number
        source_url: string
        title: string
        updated_at: string
        use_count: number
      }
      created_at: string
      cumulative_score: number
      description: string
      downvotes: number
      id: number
      importance_score: number
      is_already_viewed: boolean
      model_name: "BxBlockPosts::Post" | string
      name: string
      post_images: {
        url: string
      }[]
      post_views: number
      profile_picture: {
        url: string 
      }
      recency_score: number
      reward_point?: number
      updated_at: string
      upvotes: number
    },
    id: string,
    type: 'post'
  }[];
  isLoading: boolean;
  showCategorieCount: number;
  search: string;
  openEventModal: boolean;
  event: string;
  eventError: string;
  description: string;
  descriptionError: string;
  descriptionWordCount: number;
  isDescriptionLimitExceeded: boolean;
  date: any;
  dateError: string;
  time: any;
  timeError: string;
  uploadError: string;
  files: any;
  dateFilter: any;
  selectedTags: any;
  tagValue:any;
  index: number;
  CarosuelData: [];
  isExpanded: boolean;
  viewEvent: boolean;
  center:any;
  mapKey:string;
  suggestions:any;
  eventAddress:any;
  eventLat:any;
  eventLng:any;
  eventAddressError:string;
  eventList:any;
  filteredEvents:any;
  commonError:string;
  dataNotFound:string;
  categories:any;
  selectedItems:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewsFeedController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  inputRef: google.maps.places.SearchBox | null = null;
  getNewsFeedDataCallId: string = "";
  getMapApiKeyCallId:string="";
  createEventCallId:string="";
  getEventsApiKeyCallId:string="";
  getSearchEventsApiCallId:string="";
  getFilterEventsApiCallId:string="";
  getEventsCategoriesApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      NewsFeedData: [],
      isLoading: true,
      showCategorieCount: 4,
      search: "",
      openEventModal: false,
      eventError: "",
      event: "",
      description: "",
      descriptionError: "",
      descriptionWordCount: 0,
      isDescriptionLimitExceeded: false,
      date: null,
      dateError: "",
      time: null,
      timeError: "",
      uploadError: "",
      files: [],
      dateFilter: null,
      selectedTags: [],
      tagValue:"",
      index: 0,
      CarosuelData: [],
      isExpanded: false,
      viewEvent: false,
      center:{ lat: 48.8539428, lng: 2.3483585},
      mapKey:"",
      suggestions:[],
      eventAddress:"",
      eventLat:"",
      eventLng:"",
      eventAddressError:"",
      eventList: [],
      filteredEvents:[],
      commonError:"",
      dataNotFound:"",
      categories:[],
      selectedItems:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getNewsFeedDataCallId) {
        this.setState({ NewsFeedData: responseJson.data, isLoading: false });
      }

      switch (apiRequestCallId) {
        case this.getMapApiKeyCallId:
          this.getMapApiKeyCallIdApi(responseJson);
          break;

          case this.createEventCallId:
            this.createEventCallIdApi(responseJson);
            break;

          case this.getEventsApiKeyCallId:
            this.getEventsApiKeyCallIdApi(responseJson);
            break;

          case this.getSearchEventsApiCallId:
            this.getSearchEventsApiCallIdApi(responseJson);
            break;

          case this.getFilterEventsApiCallId:
            this.getFilterEventsApiCallIdApi(responseJson);
            break;

          case this.getEventsCategoriesApiCallId:
            this.getEventsCategoriesApiCallIdApi(responseJson);
            break;
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async getNewsFeedData(): Promise<void> {
    const token = this.state.token;
    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewsFeedDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.targetedFeedEndpoint}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        Authorization: `Bearer ${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

  async componentDidMount() {
    await this.getMapApiKey();
    this.getEvents();
    this.getEventsCategories();
  }

  getMapApiKeyCallIdApi = (responseJson: any) => {
    if (responseJson.map_key) {
      this.setState({ mapKey: responseJson.map_key });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  createEventCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.getEvents();
      this.handleCreateEventCancel();
    } else if (responseJson.message) {
      this.setState({ commonError: responseJson.message });
    } else if (responseJson.errors) {
      responseJson.errors.forEach((error: string) => {
        if (error.includes("Event date")) {
          this.setState({ dateError: error });
        }
        if (error.includes("Event time")) {
          this.setState({ timeError: error });
        }
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  getEventsApiKeyCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      const { latitude: lat, longitude: lng } = responseJson.data[0].attributes;
      this.setState({ eventList: responseJson.data, center: { lat, lng } });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  getSearchEventsApiCallIdApi = (responseJson: any) => {
    if (
      responseJson.data &&
      responseJson.data[0].message === "Not found any event."
    ) {
      this.setState({
        eventList: [],
        center: { lat: 48.8539428, lng: 2.3483585 },
        dataNotFound:responseJson.data[0].message,
      });
    } else if (responseJson.data && responseJson.data.length > 0) {
      const { latitude: lat, longitude: lng } = responseJson.data[0].attributes;
      this.setState({ eventList: responseJson.data, center: { lat, lng }, dataNotFound:"" });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  getFilterEventsApiCallIdApi = (responseJson: any) => {
    if (
      responseJson.data &&
      (responseJson.data[0].message === "No event present on selected date" ||
      responseJson.data[0].message === "No event based on filtered data") 
    ) {
      this.setState({
        dataNotFound: responseJson.data[0].message,
        eventList: [],
        center: { lat: 48.8539428, lng: 2.3483585 },
      });
    } else if (responseJson.data && responseJson.data.length > 0) {
      const { latitude: lat, longitude: lng } = responseJson.data[0].attributes;
      this.setState({
        eventList: responseJson.data,
        center: { lat, lng },
        dataNotFound: "",
        search: "",
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  getEventsCategoriesApiCallIdApi =(responseJson: any) => {
    if (responseJson.data) {
      console.log("categories data", responseJson.data);
      
      this.setState({ categories: responseJson.data});
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  handleShowMoreCategories = () => {
    const { showCategorieCount } = this.state;
    if (showCategorieCount < this.state.categories.length) {
      this.setState({ showCategorieCount: showCategorieCount + 3 });
    }
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ search: value })
    this.debouncedSearch(value);
  };

  handleCreateModal = () => {
    this.setState({ openEventModal: !this.state.openEventModal });
  };

  handleCreateEventDone = () => {
    if (this.checkCreateListEmptyFields()) {
      this.createEvent();
    }
  };

  handleCreateEventCancel = () => {
    this.setState({
      openEventModal: false,
      time: "",
      event: "",
      description: "",
      files: [],
      date: null,
      timeError: "",
      descriptionError: "",
      dateError: "",
      eventError: "",
      uploadError: "",
      eventAddress:"",
      eventAddressError:"",
      eventLat:"",
      eventLng:"",
      selectedTags:[],
      tagValue:"",
      commonError:"",
    });
  };

  handleEventChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ event: e.target.value, eventError: "" });
  };

  handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);
    const wordCount = words.length;
    const isExceeded = wordCount > 100;
    const truncatedText = isExceeded
      ? words.slice(0, 100).join(" ")
      : inputText;
    this.setState({
      description: truncatedText,
      descriptionWordCount: Math.min(wordCount, 100),
      isDescriptionLimitExceeded: isExceeded,
      descriptionError: "",
    });
  };

  handleDateChange = (date: any) => {
    this.setState({ date, dateError: "",commonError:"" });
    if (
      date instanceof Date &&
      date.toDateString() === new Date().toDateString()
    ) {
      this.setState({ time: null });
    }
  };

  handleTimeChange = (time: any) => {
    this.setState({ time, timeError: "", commonError:"" });
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.handleFiles(event.dataTransfer.files);
    }
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFiles = (files: FileList) => {
    const newFiles = Array.from(files);
    const allFiles = [...this.state.files, ...newFiles];
    const validationError = this.validateFiles(allFiles);
    if (validationError) {
      this.setState({ uploadError: validationError });
    } else {
      this.setState({ files: allFiles, uploadError: "" });
    }
  };

  validateFiles = (files: File[]) => {
    if (files.length > 4) return "You can upload a maximum of 4 files.";
    for (let file of files) {
      if (
        !["image/jpeg", "image/png"].includes(file.type) ||
        file.size > 5 * 1024 * 1024
      ) {
        return "Photo allows only jpeg or png format which is under 5MB";
      }
    }
    return "";
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      this.handleFiles(event.target.files);
    }
  };

  handleDateFilterChange = (date: Date | null) => {
    this.setState({ dateFilter: date },()=>
      this.filterEvents(this.state.selectedItems,date)
      );
  };

  handleDeleteFile = (index: number) => {
    const updatedFiles = this.state.files.filter(
      (_: any, i: any) => i !== index
    );
    this.setState({ files: updatedFiles });
  };

  isToday = (date: Date | null) => {
    return (
      date instanceof Date && date.toDateString() === new Date().toDateString()
    );
  };

  checkCreateListEmptyFields = () => {
    const { event, description, date, time, files,eventAddress,eventLat,eventLng } = this.state;
    const errors: any = {};
    let isValid = true;

    if (!event) {
      errors.eventError = "Title is required";
      isValid = false;
    }

    if (!description) {
      errors.descriptionError = "Description is required";
      isValid = false;
    }

    if (!date) {
      errors.dateError = "Date is required";
      isValid = false;
    }

    if (!time) {
      errors.timeError = "Time is required";
      isValid = false;
    }

    if (!files || files.length === 0) {
      errors.uploadError = "At least one file is required";
      isValid = false;
    }

    if (!eventAddress) {
      errors.eventAddressError = "Address is required";
      isValid = false;
    } else {
      if (!eventLat || !eventLng) {
        errors.eventAddressError = "Select a valid location";
        isValid = false;
      }
    }

    this.setState(errors);

    return isValid;
  };

  handleNext = () => {
    this.setState((prevState) => ({
      index:
        (prevState.index + 1) %
        this.state.filteredEvents[0].attributes.images.length,
    }));
  };

  handlePrevious = () => {
    this.setState((prevState) => ({
      index:
        (prevState.index -
          1 +
          this.state.filteredEvents[0].attributes.images.length) %
        this.state.filteredEvents[0].attributes.images.length,
    }));
  };

  toggleDescription = () => {
    this.setState((prevState: any) => ({ isExpanded: !prevState.isExpanded }));
  };

  handleViewEvent = (id?: any) => {
    const filteredEvents = id
      ? this.state.eventList.filter((event: any) => event.id === id)
      : this.state.eventList;
    this.setState({
      viewEvent: !this.state.viewEvent,
      isExpanded: false,
      filteredEvents,
    });
  };
  

  handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tagValue: e.target.value });
  };

  handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { tagValue, selectedTags } = this.state;
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      if (tagValue.trim()) {
        this.setState({
          selectedTags: [...selectedTags, tagValue.trim()],
          tagValue: "",
        });
      }
    }
  };

  removeTag = (index: number) => {
    const { selectedTags } = this.state;
    this.setState({
      selectedTags: selectedTags.filter((_:any, i:any) => i !== index),
    });
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };
  
  getMapApiKey = async () => {
    this.getMapApiKeyCallId = await this.apiCall({
      method: "GET",
      endPoint: "bx_block_location/locations/get_map_key",
      contentType: "application/json",
    });
  };

  handlePlaceChanged = () => {
    const places = this.inputRef?.getPlaces() || [];
    const [place] = places;
    if (place) {
      if (place.geometry && place.geometry.location) {
        const address = place.formatted_address;
        this.setState({
          eventLat: place.geometry.location.lat(),
          eventLng: place.geometry.location.lng(),
          eventAddress: address,
        });
      }
    } else {
      console.warn("No place found.");
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventAddress = event.target.value;
    this.setState({ eventAddress, eventAddressError: "",commonError:""});
  };

  createEvent = async () => {
    const {
      event,
      date,
      time,
      description,
      selectedTags,
      eventAddress,
      eventLat,
      eventLng,
      files,
    } = this.state;
    
    const formData = new FormData();
    formData.append("data[event_name]", event);
    formData.append("data[event_date]", date);
    formData.append("data[event_time]", time);
    formData.append("data[description]", description);
    formData.append("data[location]", eventAddress);
    formData.append("data[latitude]", eventLat);
    formData.append("data[longitude]", eventLng);
    formData.append("data[hashtags]", selectedTags);
    files.forEach((file: any) => {
      formData.append("data[images][]", file);
    });

    this.createEventCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_location/events",
      type: "formData",
      body: formData,
    });
  };

  handleNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  getEvents = async () => {
    this.getEventsApiKeyCallId = await this.apiCall({
      method: "GET",
      endPoint: "bx_block_location/events",
      contentType: "application/json",
    });
  };

  debounce(func: (...args: any) => any, delay: number) {
    let timeout: NodeJS.Timeout | undefined;
    return function(...args: any) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  searchEvents = async (value:string) => {
    this.getSearchEventsApiCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_location/events/search_event?data[query]=${encodeURIComponent(value)}`,
      contentType: "application/json",
    });
  };

  debouncedSearch = this.debounce(this.searchEvents, 300);

  filterEvents = async (hashtags: any, eventDate: any) => {
    const hashtagsQuery =
    hashtags.length > 0
        ? hashtags
            .map((tag: any) => `data[hashtags][]=${encodeURIComponent(tag)}`)
            .join("&")
        : `data[hashtags]=`;
        let query = hashtagsQuery;
        query += eventDate ? `&data[event_date]=${encodeURIComponent(eventDate)}` : '';

    this.getFilterEventsApiCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_location/events/filter_by?${query}`,
      contentType: "application/json",
    });
  };

  getEventsCategories = async () => {
    this.getEventsCategoriesApiCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_location/events/event_category`,
      contentType: "application/json",
    });
  };

  handleCheckboxChange = (itemTitle: string) => {
    const { selectedItems } = this.state;
    const updatedSelectedItems = selectedItems.includes(itemTitle)
      ? selectedItems.filter((title: string) => title !== itemTitle)
      : [...selectedItems, itemTitle];
    this.setState({ selectedItems: updatedSelectedItems }, () => {
      this.filterEvents(updatedSelectedItems,this.state.dateFilter);
    });
  };

  clearDateFilter = () => {
    this.setState({ dateFilter: null }, () =>
      this.filterEvents(this.state.selectedItems, this.state.dateFilter)
    );
  };
  
  // Customizable Area End
  
}
