// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  activeFlow: string;
  tabValue: number;
  eventData: {
   title: string;
   text: string;
   comments:
    {
      userName: string,
      decsription: string,
      date: string,
      time: string,
      profileUrl: string
    }[]
  }
}

interface SS {
  id: any;
}

export default class ContentModerationController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginDataApiCallId: string | null = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      activeFlow: "",
      tabValue: 0,
      eventData: {
        title: "",
        text: "",
        comments: [
          {
            userName: "",
            decsription: "",
            date: "",
            time: "",
            profileUrl: ""
          }
         ]
       }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId === this.loginDataApiCallId &&
        !responseJson.errors
      ) {
        await setStorageData("TOKEN", responseJson.meta.token);
      }
    }
  }

  async componentDidMount() {
    this.handleFakeLoginUser();
  }

  handleFakeLoginUser = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToTagList = () => {
    this.props.navigation.navigate("CreateContent");
  };

  navigateToAnalyse = () => {
    this.props.navigation.navigate("ContentList");
  };

  handleChange = (event: any, newValue: number) => {
    this.setState({tabValue: newValue});
    console.log(event)
  };
}

// Customizable Area End
