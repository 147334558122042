// Customizable Area Start
import React from "react";
import { styled } from "@mui/material";
import { Button, Typography, Avatar, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Theme } from "@mui/material/styles";
import "@mui/styles";
import Header from "../../landingpage/src/Header.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web"
import ContentModerationController, {
  configJSON,
} from "./ContentModerationController";
import customTheme from "../../../components/src/CustomTheme.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Main2 = styled("div")({
  padding: "40px 36px",
});

const MainContainer2 = styled("div")({
  borderRadius: 24,
  boxShadow: `
      0px 8px 32px 0px #0000000F,
      0px 4px 8px 0px #00000008,
      0px 25px 50px 0px #00000017
    `,
});

const MainSelection2 = styled("div")({
  paddingInline: 36,
  padding: "40px 35px",
});

const HomesLeftPanel = styled("div")({
  display: "flex",
})

const Heading2 = styled("div")({
  ...CustomFontVariant.palette.font30700,
  marginBottom: "40px"
});

const MyHomes = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "15px"
});

const AddressText = styled(Typography)({
  ...CustomFontVariant.palette.font20400,
  paddingTop: "8px"
});

const ManageButton = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
});

const Divider = styled("div")({
  borderBottom: `1px solid rgb(238, 242, 247)`,
  margin: "10px 0px",
});

const Divider2 = styled("div")({
  borderBottom: `2px solid${customTheme.palette.grey.coolGrey}`,
  margin: "60px 0px",
});

const VerticleDivider = styled("div")({
  borderLeft: `1px solid rgb(51, 65, 85)`,
  height: "17px",
  margin: "5px 10px",
});

const EventTitle = styled(Typography)({
  ...CustomFontVariant.palette.font18700,
  marginBottom: "20px"
});

const EventDescription = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  marginBottom: "20px"
});

const EventButton = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
});

const CommentText = styled(Typography)({
  ...CustomFontVariant.palette.font18400,
  paddingBottom: "10px"
});

const ShowMoreBtn = styled(Box)({
  ...CustomFontVariant.palette.font16700,
  float: "right",
  paddingRight: "21px",
  color: customTheme.palette.grey.extraLight,
  textTransform: "none",
  ...CustomFontVariant.palette.font18700,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "white"
  },
});

const address = [
  {text: "4517 Washington Ave, Manchester, Kentucky 39495"},
  {text: "6391 Elgin St. Celina, Delaware 10299"},
  {text: "2972 Westhelmer Rd. Santa Ana, Illinois 85486"},
 ];

 const events = [
  {
    title: "Lorem ipsum dolor sit amet", 
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
    comments: [
      {
        userName: "A**** ******n",
        decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "19 April 2024",
        time: "16:23 AM",
        profileUrl: "https://placekitten.com/250/250"
      },
      {
        userName: "M**** ****p",
        decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "19 April 2024",
        time: "16:23 AM",
        profileUrl: "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
      },
      {
        userName: "A*** ****t",
        decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "19 April 2024",
        time: "16:23 AM",
        profileUrl: "https://robohash.org/9498809de364275f236a3a6e07d4e2b4?set=set4&bgset=&size=400x400"
      }
    ]
  },
  {
    title: "Lorem ipsum dolor sit amet", 
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
    comments: [
      {
        userName: "A**** ******n",
        decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "19 April 2024",
        time: "16:23 AM",
        profileUrl: "https://placekitten.com/250/250"
      },
      {
        userName: "M**** ****p",
        decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "19 April 2024",
        time: "16:23 AM",
        profileUrl: "https://avatar.iran.liara.run/public/boy?username=Ash"
      },
      {
        userName: "A*** ****t",
        decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "19 April 2024",
        time: "16:23 AM",
        profileUrl: "https://loremflickr.com/250/250/dog"
      }
    ]
  }
 ];

class ContentModeration extends ContentModerationController {  

  TabPanel = (props: {children: any, value: number, index: number}) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  renderComments(comments: {
    userName: string,
    decsription: string,
    date: string,
    time: string,
    profileUrl: string
  }[]) {
    return (<>
      {comments.map((data) => {
        return <><MyHomes>
        <HomesLeftPanel>
      <Avatar
       style={{ cursor: "pointer", marginRight: "20px", width: "55px", height: "55px" }}
      src={data.profileUrl}
     />
       <div>
        <CommentText>{data.userName}</CommentText>
        <CommentText>{data.decsription}</CommentText>
        <div style={{display: "flex"}}>
        <CommentText style={{color: "rgb(51, 65, 85)"}} >{data.time}</CommentText>
        <VerticleDivider />
        <CommentText style={{color: "rgb(51, 65, 85)"}} >{data.date}</CommentText>
        </div>
       </div>
        </HomesLeftPanel>
        <MoreVertIcon />
        </MyHomes>
        <Divider2 style={{marginTop: "0px", marginBottom: "25px"}}/></>
     })}</>
    )
  }

  renderEvent() {

    return (
      <div style={{marginBottom: "60px"}}>
       <Typography style={{...CustomFontVariant.palette.font20400, paddingBottom: "10px"}}>{this.state.eventData.title}</Typography>
       <Divider />
       <AppBar position="static" style={{color: "black", background: "white", boxShadow: "none", marginTop: "70px"}}>
        <Tabs 
        data-test-id="event-tabs"
        centered 
        value={this.state.tabValue} 
        onChange={this.handleChange} 
        variant="fullWidth" 
        aria-label="full width tabs example" 
        TabIndicatorProps={{
        style: {
          backgroundColor: 'black', height: '4px',
        },
      }}>
          <Tab style={{textTransform: "none", fontSize: "16px"}} label="Comments" {...this.a11yProps(0)} />
          <Tab style={{textTransform: "none", fontSize: "16px"}} label="Reported content" {...this.a11yProps(1)} />
        </Tabs>
      </AppBar>
      <this.TabPanel value={this.state.tabValue} index={0}>
        {this.renderComments(this.state.eventData.comments)}
      </this.TabPanel>
      <this.TabPanel value={this.state.tabValue} index={1}>
        {this.renderComments(this.state.eventData.comments)}
      </this.TabPanel>
      <ShowMoreBtn>show more</ShowMoreBtn>
      </div>
    );
  }

  render() {
    return (
      <>
        <Header activeTab="" navigation={this.props.navigation} id={""} />
        <Main2>
          <MainContainer2>
            <MainSelection2>
             {this.state.activeFlow === "" && <><Heading2>{configJSON.homeTitleText}</Heading2>
                {address.map(data => {
                  return (<>
                  <MyHomes>
                  <HomesLeftPanel>
                <Avatar
                style={{ cursor: "pointer", marginRight: "20px" }}
                src={'https://avatar.iran.liara.run/public/boy?username=Ash'}
               />
                  <AddressText>{data.text}</AddressText>
                  </HomesLeftPanel>
                  <ManageButton>
                    Manage
                  </ManageButton>
                  </MyHomes>
                  <Divider />
                  </>)
                })}              
              <Divider2 />
              <Heading2>{configJSON.eventsText}</Heading2>
              {
                events.map(data => <>
                <EventTitle>{data.title}</EventTitle>
              <EventDescription>
                {data.text}
              </EventDescription>
              <EventButton  data-test-id="event-manage" onClick={() => this.setState({activeFlow: "event", eventData: data})}>Manage</EventButton>
              <Divider style={{marginTop: "25px"}} />
                </>)
              }</>}
              {this.state.activeFlow === "event" && this.renderEvent()}
            </MainSelection2>
          </MainContainer2>
        </Main2>
      </>
    );
  }
}

export default ContentModeration;

// Customizable Area End
