import React from "react";

// Customizable Area Start
import Header from "../../../blocks/landingpage/src/Header.web";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import Carousel from "react-material-ui-carousel";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import {
  add,
  checkbox,
  comment,
  disabledRsvp,
  drop,
  flag,
  like,
  privateIcon,
  publicIcon,
  rsvp,
  search,
  shareAction,
  uncheckbox,
  uploadBrowse,
  deleteIcon,
  back,
  markerIcon,
  calenderIcon,
} from "./assets";
import DatePicker from "react-datepicker";
import Comments from "../../../blocks/comments/src/Comments.web";
// Customizable Area End

import NewsFeedController, { Props } from "./NewsFeedController";

export default class NewsFeed extends NewsFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFilterSection(
    heading: string,
    items: any,
    showCount: number,
    showMoreHandler: () => void,
    isAllShown: boolean
  ) {
    return (
      <>
        <HeadingFilter>{heading}</HeadingFilter>
        <div style={{ height: 170, overflow: "auto" }}>
          {items.slice(0, showCount).map((item: any, index: any) => (
            <LocationListing key={index}>
              <Checkbox
                disableFocusRipple
                disableRipple
                icon={<img src={uncheckbox} alt="uncheckbox" />}
                checkedIcon={
                  <img
                    src={checkbox}
                    alt="checkbox"
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                checked={this.state.selectedItems.includes(item)}
                onChange={() => this.handleCheckboxChange(item)}
                data-test-id="handleCheckboxChange"
              />
              <CheckboxTitle>{item}</CheckboxTitle>
            </LocationListing>
          ))}
        </div>
        <ShowMoreButton
          disabled={isAllShown}
          onClick={showMoreHandler}
          data-test-id="showMoreHandler"
        >
          show more
        </ShowMoreButton>
      </>
    );
  }

  renderEventCard(item: any, index: number) {
    return (
      <EventCart key={index}>
        <HomeListMain>
          <div
            style={webStyle.flexPointer}
            onClick={() => this.handleViewEvent(item.id)}
            data-test-id="handleViewEvent"
          >
            <HomeListImg src={item.attributes.profile_image} />
            <HomeListInner>
              <HomeListName>
                {item.attributes.name}
                <img
                  src={
                    item.attributes.is_public === "is_public"
                      ? publicIcon
                      : privateIcon
                  }
                  alt="tag"
                />
              </HomeListName>
              <HomeListMutual>{item.attributes.event_date}</HomeListMutual>
            </HomeListInner>
          </div>
          <img
            style={{
              cursor:
                item.attributes.is_public === "is_public" ? "pointer" : "",
            }}
            src={
              item.attributes.is_public === "is_public" ? rsvp : disabledRsvp
            }
            alt="RSVP button"
          />
        </HomeListMain>
        <Divider />
        <div style={{ padding: "36px 24px 24px 24px" }}>
          <Title>{item.attributes.event_name}</Title>
          <Description>{item.attributes.description}</Description>
          <ActionButton>
            <Img src={like} alt="Like Icon" />
            <Comments navigation={this.props.navigation} id={""} />
            <Img src={shareAction} alt="Share Icon" />
            <Img src={flag} alt="Flag Icon" />
          </ActionButton>
        </div>
      </EventCart>
    );
  }

  renderHashtag() {
    const { tagValue, selectedTags } = this.state;

    return (
      <div style={{ marginTop: 30 }}>
        <Label>Add Keywords</Label>
        <Input
          type="text"
          value={tagValue}
          onChange={this.handleTagChange}
          onKeyDown={this.handleKeyPress}
          placeholder="Enter a keyword and press space or enter"
          data-test-id="handleTagChange"
        />
        {selectedTags.length > 0 && (
          <div style={webStyle.flexWrap}>
            {selectedTags.map((tag: any, index: any) => (
              <HashTag key={index}>
                <span>{tag}</span>
                <button
                  onClick={() => this.removeTag(index)}
                  data-test-id="removeTag"
                  style={webStyle.tagButton}
                >
                  ✕
                </button>
              </HashTag>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderCreateEventModal() {
    const {
      openEventModal,
      event,
      eventError,
      description,
      descriptionError,
      descriptionWordCount,
      date,
      time,
      timeError,
      dateError,
      files,
      uploadError,
      eventAddressError,
      commonError,
    } = this.state;

    return (
      <>
        {openEventModal && (
          <ParentModal>
            <ChildModal>
              <EventTitleMain>Create Event</EventTitleMain>
              {commonError && (
                <TermError>
                  <ErrorTypo>{commonError}</ErrorTypo>
                </TermError>
              )}
              <div style={{ margin: "40px 0px" }}>
                <Label>Event Name</Label>
                <Input
                  type="text"
                  placeholder="Title"
                  value={event}
                  onChange={this.handleEventChange}
                  data-test-id="handleEventChange"
                  isError={eventError}
                />
                {eventError && <ErrorMsg>{eventError}</ErrorMsg>}
              </div>
              <div style={{ margin: "40px 0px" }}>
                <Label>Description</Label>
                <TextArea
                  type="text"
                  placeholder="Description..."
                  value={description}
                  onChange={this.handleDescriptionChange}
                  data-test-id="handleDescriptionChange"
                  isError={descriptionError}
                />
                <WordCount descriptionError={descriptionError}>
                  {descriptionError && <ErrorMsg>{descriptionError}</ErrorMsg>}
                  {descriptionWordCount}/100
                </WordCount>
                <div style={{ marginTop: 24 }}>
                  <Label>Address</Label>
                  <MainDiv>
                    <StandaloneSearchBox
                      onLoad={(ref) => (this.inputRef = ref)}
                      onPlacesChanged={this.handlePlaceChanged}
                      data-test-id="handlePlaceChanged"
                    >
                      <Input
                        onChange={this.handleInputChange}
                        data-test-id="handleInputChange"
                        type="text"
                        placeholder="Address"
                        isError={eventAddressError}
                      />
                    </StandaloneSearchBox>
                    {eventAddressError && (
                      <ErrorMsg>{eventAddressError}</ErrorMsg>
                    )}
                  </MainDiv>
                </div>
                {this.renderHashtag()}
                <Grid style={{ marginTop: 20 }} container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Label>Date</Label>
                    <InputWrapper>
                      <DatePicker
                        data-test-id="handleDateChange"
                        selected={date}
                        onChange={this.handleDateChange}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select date"
                        minDate={new Date()}
                        customInput={
                          <CustomInput>
                            <InputMui
                              type="text"
                              readOnly
                              placeholder="Select date"
                              isError={!!dateError}
                              value={
                                date instanceof Date
                                  ? date?.toLocaleDateString()
                                  : ""
                              }
                            />
                          </CustomInput>
                        }
                      />
                    </InputWrapper>
                    {dateError && <ErrorMsg>{dateError}</ErrorMsg>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Label>Time</Label>
                    <InputWrapper>
                      <DatePicker
                        data-test-id="handleTimeChange"
                        selected={time}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select Time"
                        minTime={
                          this.isToday(date)
                            ? new Date()
                            : new Date(0, 0, 0, 0, 0)
                        }
                        maxTime={new Date(0, 0, 0, 23, 59)}
                        customInput={
                          <CustomInput>
                            <InputMui
                              type="text"
                              value={
                                time instanceof Date
                                  ? time.toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  : ""
                              }
                              placeholder="Select Time"
                              isError={!!timeError}
                            />
                            <IconWrapper>
                              <img src={drop} alt="drop" />
                            </IconWrapper>
                          </CustomInput>
                        }
                      />
                    </InputWrapper>
                    {timeError && <ErrorMsg>{timeError}</ErrorMsg>}
                  </Grid>
                </Grid>
                <div style={{ marginTop: 40 }}>
                  <Label>Images</Label>
                  <Grid container spacing={4} style={{ marginTop: 0 }}>
                    <Grid item xs={12} sm={4} md={4}>
                      <ContainerMain>
                        {files?.map((file: any, index: any) => (
                          <div
                            className="map-div"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <StyledImage
                              src={URL.createObjectURL(file)}
                              className="map-img"
                              alt={`photo-${index}`}
                              data-test-id="handleOpenImageModal"
                            />
                            {index === files.length - 1 && (
                              <img
                                src={deleteIcon}
                                onClick={() => this.handleDeleteFile(index)}
                                alt="delete"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                  zIndex: 0,
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </ContainerMain>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <DropZone
                        onDrop={this.handleDrop}
                        onDragOver={this.handleDragOver}
                        data-test-id="handleDragOver"
                      >
                        <DropZoneChid htmlFor="fileInput">
                          <img
                            style={{ cursor: "pointer" }}
                            src={uploadBrowse}
                            alt="uploadIcon"
                          />
                          <Title1>Upload Image</Title1>
                          <Subtitle>Supported Format: JPG, PNG</Subtitle>
                          <input
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            onChange={this.handleFileSelect}
                            data-test-id="handleFileSelect"
                            id="fileInput"
                          />
                        </DropZoneChid>
                      </DropZone>
                      {uploadError && (
                        <ErrorMsg color="error">{uploadError}</ErrorMsg>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div style={webStyle.buttonContainer}>
                  <ButtonCancel
                    variant="outlined"
                    data-test-id="handleCreateEventCancel"
                    onClick={this.handleCreateEventCancel}
                    style={{ marginRight: "1rem" }}
                  >
                    Cancel
                  </ButtonCancel>
                  <ButtonDone
                    variant="contained"
                    data-test-id="handleCreateEventDone"
                    onClick={this.handleCreateEventDone}
                  >
                    Create
                  </ButtonDone>
                </div>
              </div>
            </ChildModal>
          </ParentModal>
        )}
      </>
    );
  }

  renderCarousel = () => {
    const { isExpanded, filteredEvents } = this.state;
    const {
      profile_image,
      name,
      event_date,
      images,
      description,
      event_name,
      is_public,
    } = filteredEvents[0].attributes;
    return (
      <>
        <Back src={back} onClick={this.handleViewEvent}></Back>
        <HomeListMainView>
          <div style={webStyle.flex}>
            <HomeListImg src={profile_image} />
            <HomeListInner>
              <HomeListName>
                {name}
                <img
                  src={is_public === "is_public" ? publicIcon : privateIcon}
                  alt="tag"
                />
              </HomeListName>
              <HomeListMutual>{event_date}</HomeListMutual>
            </HomeListInner>
          </div>
          <img
            style={{ cursor: is_public === "is_public" ? "pointer" : "" }}
            src={is_public === "is_public" ? rsvp : disabledRsvp}
            alt="RSVP button"
          />
        </HomeListMainView>
        <Carousel
          data-test-id="handleNext"
          animation="fade"
          autoPlay={false}
          swipe={true}
          index={this.state.index}
          indicators={false}
          navButtonsAlwaysVisible={true}
          next={this.handleNext}
          prev={this.handlePrevious}
          navButtonsProps={{
            style: webStyle.navButtonsProps,
          }}
        >
          {images.map((item: any) => (
            <Box key={item.id} style={webStyle.carousel}>
              <img
                style={webStyle.carouselImage}
                src={item.file_url}
                alt={`Slide ${item.id}`}
              />
            </Box>
          ))}
        </Carousel>
        <div style={{ margin: "24px 0px" }}>
          <ActionButton>
            <Img src={like} alt="Like Icon" />
            <Img src={comment} alt="Comment Icon" />
            <Img src={shareAction} alt="Share Icon" />
            <Img src={flag} alt="Flag Icon" />
          </ActionButton>
        </div>
        <HomeListName>{event_name}</HomeListName>
        <DescriptionView>
          {isExpanded ? description : `${description.slice(0, 200)}...`}
        </DescriptionView>
        {!isExpanded && description.length > 200 && (
          <ShowMoreButton
            onClick={this.toggleDescription}
            data-test-id="toggleDescription"
          >
            see more...
          </ShowMoreButton>
        )}
      </>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { showCategorieCount, viewEvent, mapKey } = this.state;
    const isAlLCategoriesShown =
      showCategorieCount >= this.state.categories.length;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} id={""} activeTab="5" />
        {viewEvent ? (
          <MainContainerView>{this.renderCarousel()}</MainContainerView>
        ) : (
          <>
            {mapKey && (
              <LoadScript
                googleMapsApiKey={this.state.mapKey}
                libraries={["places"]}
              >
                <MainContainer>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                      <SideMainContainer>
                        <Heading>Select filter</Heading>
                        <DateMain>
                          <img
                            src={calenderIcon}
                            alt="icon"
                            style={{ width: 24, height: 24 }}
                          />
                          <DatePicker
                            className="datepicker"
                            data-test-id="handleDateFilterChange"
                            selected={this.state.dateFilter}
                            onChange={this.handleDateFilterChange}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                              <div>
                                <InputMuiDate
                                  type="text"
                                  readOnly
                                  placeholder="Select date"
                                  value={
                                    this.state.dateFilter instanceof Date
                                      ? this.state.dateFilter?.toLocaleDateString()
                                      : ""
                                  }
                                />
                              </div>
                            }
                          />
                          <button
                            style={webStyle.closeButton}
                            onClick={this.clearDateFilter}
                          >
                            ✕
                          </button>
                        </DateMain>
                        {this.renderFilterSection(
                          "Categories",
                          this.state.categories,
                          this.state.showCategorieCount,
                          this.handleShowMoreCategories,
                          isAlLCategoriesShown
                        )}
                      </SideMainContainer>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <div style={webStyle.flex}>
                        <EventTitle>Events</EventTitle>
                        <ButtonContained
                          variant="outlined"
                          onClick={this.handleCreateModal}
                          data-test-id="handleCreateModal"
                        >
                          <img src={add} style={{ paddingRight: 8 }} />
                          Create Event
                        </ButtonContained>
                      </div>
                      <SearchInputParent>
                        <img
                          src={search}
                          alt="search"
                          style={{ paddingRight: 8 }}
                        />
                        <SearchInput
                          type="search"
                          placeholder="Search events"
                          value={this.state.search}
                          onChange={this.handleSearch}
                          data-test-id="handleSearch"
                        ></SearchInput>
                      </SearchInputParent>
                      <GoogleMap
                        mapContainerStyle={webStyle.mapContainerStyle}
                        zoom={12}
                        center={this.state.center}
                        options={{
                          disableDefaultUI: true,
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                      >
                        {this.state.eventList?.map((marker: any) => (
                          <>
                            <Marker
                              key={marker.id}
                              position={{
                                lat: marker.attributes.latitude,
                                lng: marker.attributes.longitude,
                              }}
                              icon={markerIcon}
                              data-test-id="handleMainModal"
                            />
                            <OverlayView
                              key={`overlay-${marker.id}`}
                              position={{
                                lat: marker.attributes.latitude,
                                lng: marker.attributes.longitude,
                              }}
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                              <MarkerName>
                                {marker.attributes.event_name}
                              </MarkerName>
                            </OverlayView>
                          </>
                        ))}
                      </GoogleMap>
                      <div style={{ maxHeight: 800, overflow: "auto" }}>
                        {this.state.eventList?.map((item: any, index: any) =>
                          this.renderEventCard(item, index)
                        )}
                        {this.state.dataNotFound && (
                          <DataNotFound>{this.state.dataNotFound}</DataNotFound>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  {this.renderCreateEventModal()}
                </MainContainer>
              </LoadScript>
            )}
          </>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: { [key: string]: any } = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexPointer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
    marginTop: 16,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40,
  },
  container: {
    maxWidth: "100%",
    padding: 0,
    height: 500,
    overflow: "hidden",
  },
  carousel: {
    flex: 1,
    maxHeight: 500,
    overflow: "hidden",
    borderRadius: 8,
  },
  carouselImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  navButtonsProps: {
    backgroundColor: "#fff",
    color: "#000",
    width: "24px",
    height: "24px",
    margin: "0px 27px",
  },
  mapContainerStyle: {
    width: "100%",
    height: "100%",
    maxHeight: 450,
    borderRadius: 24,
    marginTop: 24,
  },
  tagButton: {
    marginLeft: 8,
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: 16,
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: 16,
  },
};

const MainContainer = styled("div")({
  margin: "62px 100px 10px 100px",
});

const MainContainerView = styled("div")({
  margin: "30px 100px 30px 100px",
});

const SideMainContainer = styled("div")({
  padding: "8px 24px 24px 24px",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  "& .datepicker input::placeholder": {
    color: customTheme.palette.black.secondary,
    opacity: 1,
  },
});

const Heading = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18700,
});

const ButtonContained = styled(Button)({
  padding: "10px 20px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  margin: "30px 0px",
  backgroundColor: customTheme.palette.grey.primary,
});

const HeadingFilter = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18700,
  marginBottom: 16,
});

const CheckboxTitle = styled("div")({
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16400,
});

const LocationListing = styled("div")({
  display: "flex",
  alignItems: "center",
});

const ShowMoreButton = styled(Box)(({ disabled }: any) => ({
  color: customTheme.palette.grey.extraLight,
  textTransform: "none",
  display: disabled ? "none" : "block",
  ...CustomFontVariant.palette.font16700,
  cursor: "pointer",
}));

const EventTitle = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font24700,
});

const Title = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font20700,
  marginBottom: 24,
});

const EventTitleMain = styled("div")({
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font20700,
});

const Description = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18400,
  marginBottom: 24,
});

const DescriptionView = styled("div")({
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font16400,
  margin: "16px 0px 8px 0px",
});

const SearchInputParent = styled("div")({
  borderRadius: 8,
  border: `1px solid ${customTheme.palette.grey.light}`,
  display: "flex",
  alignItems: "center",
  padding: 8,
});

const SearchInput = styled("input")({
  border: "none",
  width: "100%",
  "&:focus": {
    outline: "none",
  },
  ...CustomFontVariant.palette.font16400,
});

const HomeListMain = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 12,
  paddingInline: 20,
});

const HomeListMainView = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 12,
});

const HomeListName = styled("div")({
  ...CustomFontVariant.palette.font16700,
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const HomeListMutual = styled("div")({
  ...CustomFontVariant.palette.font14400,
  color: customTheme.palette.grey.main,
});

const HomeListImg = styled(Avatar)({
  width: 56,
  height: 56,
});

const HomeListInner = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingLeft: 10,
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const EventCart = styled("div")({
  borderRadius: 24,
  margin: "24px 0px",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
});

const ActionButton = styled("div")({
  display: "flex",
  gap: 16,
});

const Img = styled("img")({
  cursor: "pointer",
});

const Label = styled("label")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
});

const Input = styled("input")(({ isError }: any) => ({
  marginTop: 8,
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const TextArea = styled("textarea")(({ isError }: any) => ({
  marginTop: 8,
  height: 121,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const ErrorMsg = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const WordCount = styled("div")(({ descriptionError }: any) => ({
  display: "flex",
  justifyContent: descriptionError ? "space-between" : "end",
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.secondary,
}));

const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
  "& > :first-child": {
    width: "100%",
  },
});

const IconWrapper = styled("div")({
  position: "absolute",
  right: 12,
  top: 36,
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
});

const CustomInput = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&:hover": {
    cursor: "pointer",
  },
});

const InputMui = styled("input")(({ isError }: any) => ({
  marginTop: 8,
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const DropZone = styled("div")({
  width: "100%",
  height: "auto",
  maxHeight: 178,
  border: `2px dashed ${customTheme.palette.grey.light}`,
  borderRadius: 16,
  boxShadow: "0px 4px 8px 0px #00000008",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "4/3",
});

const DropZoneChid = styled("label")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
});

const Title1 = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.extraLight,
});

const Subtitle = styled("div")({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.extraLight,
});

const ContainerMain = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  columnGap: "9px",
  rowGap: "6px",
  width: "100%",
  height: "100%",
  position: "relative",
  maxHeight: 186,
  "& .map-div": {
    height: 89.5,
  },
  "& .map-img": {
    position: "relative",
  },
  "& div:last-child .map-img": {
    filter: "brightness(0.5)",
    position: "relative",
    "&::after": {
      content: '""',
      display: "block",
      width: "24px",
      height: "24px",
      position: "absolute",
      top: "8px",
      right: "8px",
      backgroundImage: `url(${deleteIcon})`,
      backgroundSize: "cover",
      cursor: "pointer",
    },
  },
});

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ParentModal = styled(Box)({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const ChildModal = styled(Box)({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "36px 24px",
  borderRadius: "20px",
  margin: "150px auto",
  position: "relative",
});

const ButtonDone = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  backgroundColor: `${customTheme.palette.grey.primary}!important`,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
});

const ButtonCancel = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
  borderColor: `${customTheme.palette.black.primary}!important`,
});

const HashTag = styled("div")({
  color: customTheme.palette.black.primary,
  backgroundColor: customTheme.palette.grey.super,
  ...CustomFontVariant.palette.font14400,
  width: "fit-content",
  height: 32,
  padding: "6px 12px",
  borderRadius: 26,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

const Back = styled("img")({
  marginBottom: 20,
  cursor: "pointer",
});

const MarkerName = styled("div")({
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font14700,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const MainDiv = styled("div")({
  margin: "8px 0px 40px 0px",
});

const TermError = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
});

const ErrorTypo = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...CustomFontVariant.palette.font12400,
  padding: "20px 16px",
  maxWidth: 328,
});

const DataNotFound = styled("div")({
  margin: 40,
  display: "flex",
  justifyContent: "center",
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font20700,
});

const DateMain = styled("div")({
  border: `1px solid ${customTheme.palette.black.primary}`,
  borderRadius: 8,
  margin: "30px 0px",
  display: "flex",
  padding: 10,
  position: "relative",
  alignItems: "center",
});

const InputMuiDate = styled("input")({
  width: "100%",
  border: "none",
  paddingLeft: 4,
  "&:focus": {
    outline: "none",
  },
});

// Customizable Area End
